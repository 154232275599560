@import 'abstracts';
@import 'components/custom-dialog';

@include custom-dialog;

.popup-content {
  min-height: 400px;
}

app-un-match-sales {
  cpb-list-renderer {
    .cap-info-table.p-datatable {
      .p-datatable-thead {

        /* stylelint-disable selector-class-pattern */
        .th_select {
          width: 5%;

          div {
            visibility: hidden;
          }
        }
      }
    }
  }
}
